$font: tahoma, sans-serif;
body {
  margin: 0;
  font-family: $font;
}

img {
  max-width: 100%;
}

.flex-Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.absolute-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.m-5-0 {
  margin: 5px 0;
}

.m-5 {
  margin: 5px;
}

.m-0 {
  margin: 0 !important;
}

.m-auto {
  margin: 0 auto;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #ccc;
}

.required-inp {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a80202;
    opacity: 1; /* Firefox */
  }
}

.datepicker-content,
.datepicker-navbar {
  font-family: $font;
}

.ant-btn-primary {
  //color: #fff;
  //background: $intro-primary-color;
  //border-color: $intro-primary-color;

  //&:hover,
  //&:focus {
  //  background: $intro-primary-color;
  //  border-color: $intro-primary-color;
  //}
}

.submit-btn {
  button {
    background: transparent linear-gradient(269deg, #242b3e 0%, #485969 100%) 0
      0 no-repeat padding-box;
    box-shadow: 0 6px 10px #0000003b;
    border-radius: 40px;
    color: #fff;
    width: 100%;
    border: none;
    height: 46px;
    max-width: 300px;
  }
}

@import "./variables";

body {
  height: 100%;
  overflow-y: hidden;
  background-color: #f8f8f8;
}

// ::-webkit-scrollbar-thumb {
//   background-color: #e6e6e6;
// }

// ::-webkit-scrollbar {
//   width: 30px;
//   height: 30px;
// }

.margin-right {
  margin-right: 16px;
}

:global {
  .ant-breadcrumb {
    & > span {
      &:last-child {
        color: #999;
        font-weight: normal;
      }
    }
  }

  .ant-breadcrumb-link {
    .anticon + span {
      margin-left: 4px;
    }
  }

  .ant-table {
    .ant-table-thead > tr > th {
      text-align: center;
    }

    .ant-table-tbody > tr > td {
      text-align: center;
    }

    &.ant-table-small {
      .ant-table-thead > tr > th {
        background: #f7f7f7;
      }

      .ant-table-body > table {
        padding: 0;
      }
    }
  }

  .ant-table-pagination {
    float: none !important;
    display: table;
    margin: 16px auto !important;
  }

  .ant-popover-inner {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
  }

  .ant-form-item-control {
    vertical-align: middle;
  }

  .ant-modal-mask {
    background-color: rgba(55, 55, 55, 0.2);
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item {
    padding: 12px 16px !important;
  }

  a:focus {
    text-decoration: none;
  }

  .ant-table-layout-fixed table {
    table-layout: auto;
  }
}

.ant-menu-inline .ant-menu-item::after {
  border-right-color: darkred;
}

.ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: #c10202;
  &:hover {
    color: #c10202;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffe6e6;
}

.ant-menu-submenu-selected {
  color: #c10202;

  .anticon {
    &:hover {
      color: #c10202;
    }
  }

  &:hover {
    color: darkred;
  }
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #e61313 !important;
}

.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active {
  color: #e61313 !important;
  background-color: #ffe6e6;
}
.ant-menu-item,
.ant-menu-item-icon,
.ant-menu-submenu-title {
  &:hover {
    color: #e61313 !important;
    background-color: #ffe6e6;
  }
}

.ant-menu-item a:hover,
.ant-menu-item-selected {
  color: #e61313 !important;
}

@media (min-width: 1600px) {
  :global {
    .ant-col-xl-48 {
      width: 20%;
    }

    .ant-col-xl-96 {
      width: 40%;
    }
  }
}

@media (max-width: 767px) {
  :global {
    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-options,
    .ant-pagination-prev {
      margin-bottom: 8px;
    }

    .ant-card {
      .ant-card-head {
        padding: 0 12px;
      }

      .ant-card-body {
        padding: 12px;
      }
    }
  }
}

@media print {
  .hide-print {
    display: none;
  }
}

.ant-message-notice {
  display: flex;
  justify-content: center;

  .ant-message-notice-content {
    margin-top: 10vh;
    padding: 0px;
    width: 40%;

    // fixme: Please handle duplicate @ziba

    .ant-message-custom-content {
      padding: 13px 16px;
      text-align: left;

      :nth-child(2) {
        display: flex;
        margin-left: 36px;
      }
      .anticon {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 15px;
        &:after {
          font-size: 21px;
          padding-left: 0.5em;
        }

        svg {
          display: inline-block;
          font-size: 1.5em;
        }
      }
    }

    .ant-message-warning {
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
      animation-duration: 5s;
      .anticon-exclamation-circle {
        &:after {
          content: "Warning";
        }
      }
    }

    .ant-message-info {
      background-color: #e6f7ff;
      border: 1px solid #1890ff;
      .anticon-info-circle {
        &:after {
          content: "Informational Notes";
        }
      }
    }

    .ant-message-success {
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
      .anticon-check-circle {
        &:after {
          content: "Success Tips";
        }
      }
    }

    .ant-message-error {
      background-color: #fff2f0;
      border: 1px solid #ffccc7;
      .anticon-close-circle {
        &:after {
          content: "Error";
        }
      }
    }
  }
}

.multi-select-mall {
  background: dodgerblue;
  position: absolute;
  bottom: 68%;
  padding: 9px;
  border: 1px;
  border-start-start-radius: 5px;
  border-end-end-radius: 5px;
  z-index: 99;
  max-width: 150%;
  width: max-content;

  @media (max-width: 768px) {
    max-width: 63%;
    position: fixed;
    top: 6%;
    margin: 0 auto;
    display: table;
    right: 0;
    left: 0;
  }
}

.filters {
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  margin: 1em 0;
  padding: 15px;
  .down-icon {
    border: 0px;
    border-left: 1px solid #ccc;
  }
  .anticon-down {
    color: #ccc !important;
  }
  .ant-col {
    padding: 0.4em;
  }
  .btn-filter {
    margin-top: 1em;
  }
  .label {
    text-transform: capitalize;
  }
  .ant-input-affix-wrapper {
    padding: 1px 2px !important;
  }

  button {
    @media (max-width: 1200px) {
      margin: 0.5em;
    }
  }
  .search-filter {
    input {
      padding: 0.3em 0.4em;
    }
  }
}

.add-button-style {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-add-items {
    display: flex;
    width: 23px;
    height: 23px;
    align-items: center;
  }
}

.add-location-wrapper {
  // height: max-content;
  height: 70vh;
  margin: 1em;
  overflow: hidden;

  .map-in-modal {
    width: 100%;
    height: 25rem;

    .marker {
      font-size: 2.5rem;
      cursor: pointer;
    }

    .user-tip {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.8rem;
      font-weight: 500;
      font-family: IRANSansWeb, serif;
      padding: 10px;
      background-color: hsla(0, 0%, 100%, 0.5);
      margin: 0;
    }

    .submit-btn {
      position: absolute;
      bottom: 8ch;
      left: 2ch;
      z-index: 2;
      font-size: 0.8rem;
      font-weight: 500;
      font-family: IRANSansWeb, serif;
      margin: 0;
      width: 20ch;
    }

    .add-location-modal {
      width: 100%;
    }
  }
}

.mapboxgl-ctrl-top-left {
  position: absolute;
  pointer-events: none;
  z-index: 999;
}

.range-picker {
  input {
    border: 1px solid #d9d9d9;
    padding: 6px;

    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      z-index: 1;
    }

    &:focus {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: #1890ff33;
      box-shadow: #1890ff33;
    }
  }
}
