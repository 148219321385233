.login {
  position: relative;
  width: 100vw;
  height: 100vh;

  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 320px;
    height: max-content;
    padding: 36px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

    button {
      width: 100%;
    }

    p {
      color: rgb(204, 204, 204);
      text-align: center;
      margin-top: 16px;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
    }
  }

  .logo {
    cursor: pointer;
    margin-bottom: 24px;
    display: grid;
    text-align: center;
    text-align: -webkit-center;
    display: flex;
    justify-content: center !important;

    img {
      width: 90px;
      margin-bottom: 1rem;
    }

    span {
      vertical-align: text-bottom;
      font-size: 16px;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 700;
      color: #485969;
      .text-gradient {
        background-image: -webkit-gradient(
          linear,
          37.219838% 34.532506%,
          36.425669% 93.178216%,
          from(#29cdff),
          to(#0a60ff),
          color-stop(0.37, #148eff)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .ant-spin-container,
  .ant-spin-nested-loading {
    height: 100%;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
