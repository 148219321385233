@mixin mainPadding {
  padding: 1rem 3rem;
}

.file-manager-modal {
  //width: 80% !important;

  .file-manager {
    width: 100%;
    border: 1px solid #f0f0f0;

    .upload-file-button {
      width: 100px;
    }

    .header {
      width: 100%;
      @include mainPadding;

      .header-items-wrapper {
        display: flex;
        justify-content: flex-start;

        .upload-btn {
          display: grid;
          place-content: center;

          .anticon {
            font-size: 1.2rem;
          }

          & > p {
            margin-top: 0.3rem;
            margin-bottom: 0;
          }
        }

        .new-folder-btn {
          display: grid;
          place-content: center;

          .anticon {
            font-size: 1.2rem;
          }

          & > p {
            margin-top: 0.3rem;
            margin-bottom: 0;
          }
        }

        .reload-btn {
          display: grid;
          place-content: center;

          .anticon {
            font-size: 1.2rem;
          }

          & > p {
            margin-top: 0.3rem;
            margin-bottom: 0;
          }
        }

        .back-btn {
          display: grid;
          place-content: center;

          .anticon {
            font-size: 1.2rem;
          }

          & > p {
            margin-top: 0.3rem;
            margin-bottom: 0;
          }
        }
      }
    }

    & > .ant-divider-horizontal {
      margin: 0;
    }

    .main-row {
      .file-wrapper {
        @include mainPadding;
      }

      .files-wrapper {
        height: auto;
        min-height: 61.5ch;

        .files {
          @include mainPadding;

          .file-card {
            width: 100%;

            & > .anticon-folder-open {
              font-size: 57px;
            }
          }
        }
      }

      .directories-wrapper {
        height: auto;
        min-height: 61.5ch;
        border-right: 1px solid #f0f0f0;
      }
    }
  }

  .directories {
    padding: 1rem 0;
    max-height: 100vh;
    overflow: auto;
  }

  .modal-custom-header {
    padding-bottom: 10px;
    border-bottom: solid 1px #eee;
    margin-bottom: 10px;

    .ant-typography {
      display: inline-block;
      padding-left: 20px;
    }
  }
}
