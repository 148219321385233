.rate-info {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .rate-count,
  & > .ant-rate {
    justify-self: center;
    place-self: center;
  }

  & > label {
    justify-self: center;
    place-self: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  & > .ant-input {
    border: none;
    text-align: center;
  }
}
