.user-profile {
  text-align: center;
  .profile-image {
    text-align: center;
    .ant-row {
      width: 100%;
      display: table;
      margin: 5px;
    }
    .upload-image {
      margin-right: 0.5em;
    }
    .ant-image {
      border-radius: 50%;
    }
    img {
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
  }

  .ant-form-item-label {
    label {
      font-family: sans-serif;
      font-size: 1.3em;
    }
  }
  .icon-remove {
    width: 100%;
  }
}

.update-button {
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.3em;
  .ant-btn {
    width: 150px;
  }
  .check-circle {
    font-size: 20px;
  }
}

.stripe-connect {
  background: #635bff;
  text-decoration: none;
  width: 180px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  height: 90%;
  padding: 10px;
  justify-content: center;
  &:active,
  &:focus,
  &:hover {
    background: #635bff;
  }
  span {
    color: #ffffff;
    display: block;
    font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    &::after {
      background-repeat: no-repeat;
      background-size: 49.58px;
      content: "";
      height: 20px;
      left: 62%;
      position: absolute;
      top: 28.95%;
      width: 49.58px;
    }
  }
  &:hover {
    background: #7a73ff;
  }
}
.slate {
  background: #0a2540;
  &:hover {
    background: #425466;
  }
}
.white {
  background: #43a047 !important;
  left: 1px;
  position: relative;
  top: 1px;
  span {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 13px;
    margin-right: 5px;
    &:hover {
      background: #f6f9fc;
    }
  }
}
.field-width {
  width: 60%;
}
