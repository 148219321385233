.chat-box {
  max-height: 350px;
  overflow: auto;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
}

.chat-box:has(.comment-content){
  max-height: unset;
}

.comment-content{
  a{
    background: url("/images/logo.svg") no-repeat center;
    min-height: 180px;
    display: block;

    img{
      padding: 1rem;
    }
  }

  p{
    border-bottom: 2px solid rgba(0,0,0,0.12);
    padding-bottom: 1rem;
  }
}