.crud {
  .header {
    margin-bottom: 12px;

    button {
      margin-left: 10px;
    }
  }

  .body {
  }
}
