.dashboard {
  width: 100%;
  min-height: calc(100vh - 230px);
}
.card-space {
  margin: 1em 0;
}
.result {
  margin: 0 auto;
}
.ant-card {
  background: #f2f3f54d;
  @media screen and (min-width: 1300px) {
    width: 80%;
    margin: 0 auto;
  }
}
.icons-dashboard {
  width: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 80px;
  border-radius: 5px;
  color: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  bottom: 40px;
  svg {
    font-size: 3em;
  }
}
.ant-card-meta-title {
  text-overflow: clip !important;
  font-size: 25px;
}
.ant-card-meta-description {
  font-size: 18px;
}

.ant-statistic-title {
  height: 2em;
}
