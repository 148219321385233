$fonts-url: "../fonts/iransans";
//$fonts-url: "../fonts/iransans-persian-digits";
@font-face {
  font-family: IRANSansWeb;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url($fonts-url + "/woff/IRANSansWeb.woff") format("woff");
}

@font-face {
  font-family: IRANSansWeb;
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: url($fonts-url + "/woff/IRANSansWeb_UltraLight.woff") format("woff");
}

@font-face {
  font-family: IRANSansWeb;
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url($fonts-url + "/woff/IRANSansWeb_Light.woff") format("woff");
}

@font-face {
  font-family: IRANSansWeb;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url($fonts-url + "/woff/IRANSansWeb_Medium.woff") format("woff");
}

@font-face {
  font-family: IRANSansWeb;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url($fonts-url + "/woff/IRANSansWeb_Bold.woff") format("woff");
}
