@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin absoluteCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
}

.site-layout {
  .site-layout-background {
    background: #fff;
    padding: 0;
    position: relative;
    display: flex;

    .sign-out-wrapper {
      width: 93%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .header-col {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.2rem;
      }

      .profile-btn {
        @include flexCenter;
        justify-content: space-evenly;
        width: max-content;
        padding-left: 2ch;
        padding-right: 2ch;
        color: #000;
        border: none;

        .profile {
          margin-left: 0.5rem;
        }
      }

      .header-wrapper {
        width: 50ch;
        justify-content: space-evenly;

        .language-selector {
          width: 120px;
        }
      }
    }
  }

  #page-container {
    //user-select: none;
    padding: 24px;
    //   min-height: unset;
    overflow: auto;
    height: 100%;
    .page-container-content {
      background: #f0f2f500;
      padding: 24px;
      box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
      border-radius: 5px;
      overflow: auto;
      height: 100%;
      .page-container-child {
        background: #fff;
        padding: 1em;
      }

      &.fullscreen-page {
        padding-top: 0;
        z-index: 1;
        min-height: 100vh;
      }

      &.no-padding {
        padding: 0;
      }

      &.header-pos-fix {
        padding-top: 54px;
      }
    }

    .container {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    @media (min-width: 768px) {
      .container {
        width: 750px;
      }
    }
    @media (min-width: 992px) {
      .container {
        width: 970px;
      }
    }
    @media (min-width: 1200px) {
      .container {
        width: 1170px;
      }
    }

    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .footer {
    text-align: center;
    color: #919194;
  }
}

.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
}

.popover-balance {
  position: relative;
  top: -4px;
  font-size: 10px;
}
.ant-dropdown {
  z-index: 0;
}
.drop-down-balance {
  span {
    width: 50px;
    display: inline-flex;
  }
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 70%;
    min-width: 90%;
    margin: 0px 8px 0;
  }
  .ant-dropdown-menu-submenu-title {
    line-height: 30px;
  }
}
