@media print {
  #root {
    display: none;
  }

  .ant-modal-content {
    background: none;
    box-shadow: unset;
    -webkit-box-shadow: unset;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    background: none;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-modal-footer {
    display: none;
  }
}
.code {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
}
.badge-width {
  .ant-badge-status-dot {
    width: 15px;
    height: 15px;
  }
}
.locked-badge {
  span {
    color: green;
  }
}

.select-state {
  width: 100%;
  margin: 1em 0;
  .style-state {
    width: 100%;
  }
}

.add-mallName {
  position: absolute;
  right: 34px;
  padding: 0.5em;
  font-size: 1em;
  .title-mallName {
    font-weight: bold;
  }
}
