.subscriber-extra-info {
  .disabled-info {
    color: unset;
    background-color: unset;
    opacity: 1;
    border-color: transparent;

    &:hover {
      border-color: transparent !important;
    }
  }

  .professional-info,
  .skills-wrapper,
  .form-wrapper {
    width: 100%;
    padding: 1rem 2rem;
  }

  .lawyer-submit-button {
    width: max-content;
    padding-left: 3ch;
    padding-right: 3ch;
  }
}
